import * as Sentry from "@sentry/gatsby";

import packageJson from "./package.json";

Sentry.init({
  debug: process.env.GATSBY_SENTRY_DEBUG === "true",
  dsn: process.env.GATSBY_SENTRY_DSN,
  sampleRate: 1.0,
  tracesSampleRate: 1.0, // Adjust this value in production
  release: packageJson.version,
  environment: process.env.GATSBY_DEPLOY_ENVIRONMENT,
  replaysOnErrorSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  // TODO investigate on further configurations
  // beforeSend(event) {
  //   // Modify the event here
  //   if (event.user) {
  //     // Don't send user's email address
  //     delete event.user.email;
  //   }
  //   return event;
  // },
  enabled: process.env.EXCLUDE_SENTRY !== "true",
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
});

Sentry.getCurrentScope().setTag("environment", process.env.GATSBY_DEPLOY_ENVIRONMENT);
